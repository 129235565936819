import { defineStore } from 'pinia';

export const useSystemStore = defineStore('useSystemStore', {
  state() {
    return {
      code: 0,
    };
  },
  getters: {},
  actions: {
    setCode(code: number) {
      this.code = code;
    },
  },
});
