import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import routes from './routes';
import { useSystemStore } from '@/store';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const systemStore = useSystemStore();
  document.title = 'Zeniein的摸鱼小岛';
  if (to.meta.code) {
    systemStore.setCode(to.meta.code as number);
  }
  next();
});

export default router;
