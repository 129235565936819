import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      code: 200,
    },
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: '',
        name: 'pageHome',
        meta: {
          code: 200,
          title: '首页',
        },
        component: () => import('@/views/page/PageView.vue'),
      },
      {
        path: 'pages/:page',
        name: 'pages',
        meta: {
          code: 200,
          title: '首页',
        },
        component: () => import('@/views/page/PageView.vue'),
      },
      {
        path: 'search',
        name: 'search',
        meta: {
          code: 200,
          title: '搜索',
        },
        component: () => import('@/views/search/SearchView.vue'),
      },
      {
        path: 'search/:keyword',
        name: 'searchByKey',
        meta: {
          code: 200,
          title: '搜索',
        },
        component: () => import('@/views/search/SearchResult.vue'),
      },
      {
        path: 'article/detail/:id',
        name: 'detail',
        meta: {
          code: 200,
          title: '文章',
        },
        component: () => import('@/views/article/DetailsView.vue'),
      },
      {
        path: 'category',
        name: 'category',
        meta: {
          code: 200,
          title: '分类',
        },
        component: () => import('@/views/category/CategoryView.vue'),
      },
      {
        path: 'category/:path',
        name: 'categoryDetails',
        meta: {
          code: 200,
          title: '分类',
        },
        component: () => import('@/views/category/CategoryDetailsView.vue'),
      },
      {
        path: 'friend',
        name: 'friend',
        meta: {
          code: 200,
          title: '友链',
        },
        component: () => import('@/views/friend/FriendView.vue'),
      },
      {
        path: 'message',
        name: 'message',
        meta: {
          code: 200,
          title: '留言板',
        },
        component: () => import('@/views/message/UserMessageView.vue'),
      },
      {
        path: 'about',
        name: 'about',
        meta: {
          code: 200,
          title: '关于',
        },
        component: () => import('@/views/about/AboutView.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    name: 'PageNotExist',
    component: () => import('@/views/error/404.vue'),
  },
];

export default [...routes];
